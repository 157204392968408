import { ERouteNames } from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'NotFoundView',
	data() {
		return {
			homeRouteName: ERouteNames.Home
		};
	}
});
